body{
  background-color: rgb(64, 64, 64);
}

/* Define global CSS variables inside :root */
:root {
  --cli-font-size: 16px; /* Adjust font size as needed */
  --cli-font-family: monospace;
  --cli-background-color: black;
  --cli-text-color: limegreen;
  --cli-input-background-color: black;
  --cli-input-text-color: limegreen;
  --cli-border-color: limegreen;
}

.cli {
  background-color: var(--cli-background-color);
  color: var(--cli-text-color);
  font-family: var(--cli-font-family);
  font-size: var(--cli-font-size);
  padding: 20px;
  text-align: left;
  height: 100vh;
  overflow-y: auto;
}

.cli-header {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--cli-border-color);
  margin-bottom: 10px;
}

.cli-body {
  list-style-type: none;
  padding: 0;
}

.cli-input {
  background-color: var(--cli-input-background-color);
  color: var(--cli-input-text-color);
  border: none;
  outline: none;
  font-family: var(--cli-font-family);
  font-size: var(--cli-font-size); /* Use the same font size for consistency */
}

.cli-input-prompt, .cli-input {
  display: inline;
}

li {
  margin-bottom: 4px;
}

span {
  color: var(--cli-text-color); /* Use CSS variable for text color */
}

/* Additional styles for the CLI component */
.cli {
  background-color: black;
  color: limegreen;
  font-family: monospace;
  padding: 20px;
  text-align: left;
  overflow-y: auto;
}

.cli-header {
  padding-bottom: 10px;
  border-bottom: 1px solid limegreen;
  margin-bottom: 10px;
}

.cli-body, .cli ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Adjust padding as needed */
}

.cli-input {
  background-color: black;
  color: limegreen;
  border: none;
  outline: none;
  font-family: monospace;
  font-size: 1em;
}

.cli-input-prompt, .cli-input {
  display: inline;
}

li {
  margin-bottom: 4px;
}

/* ContactMe.css */
.contact-container {
  text-align: center; /* Center the text and content inside the container */
  width: 100%; /* Ensure the container spans the full width */
  margin: 0 auto; /* Center the container */
  padding: 20px 0; /* Add some padding above and below for spacing */
}

.contact-button {
  margin: 10px; /* Add some margin around buttons for spacing */
  background-color: #000; /* Button background color */
  border: 2px solid #000; /* Button border */
  border-radius: 50%; /* Make buttons circular */
  width: 60px; /* Width of the button */
  height: 60px; /* Height of the button */
  display: inline-flex; /* Use inline-flex to keep buttons in line */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.5s; /* Smooth transition for hover effect */
}

.contact-button:hover {
  background-color: #f0f0f0; /* Slight background color change on hover */
}

.contact-svg {
  width: 50px; /* Set the SVG width */
  height: 50px; /* Set the SVG height */
  pointer-events: none; /* Prevent SVG from capturing click events */
  filter: brightness(0) saturate(50%) invert(90%); /* Example filter */
}

.cli-output {
  white-space: pre-wrap; /* Preserves whitespace and line breaks */
}
